import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Alert from "./components/Alert";
//import App from "./App";

/* uncomment for testing locally
window.lifeformsSettings = {
	id: "lirgala", // lirgala
};
*/

/* closed, replace <div></div> below
	<App />
*/

const target = document.getElementById("lifeforms-root");
if (target) {
	if (window.lifeformsSettings) {
		const { id } = window.lifeformsSettings;

		ReactDOM.render(
			<React.StrictMode>
				{id === "lirgala" ? (
					<div style={{ paddingTop: "25px", paddingBottom: "25px" }}>
						<Alert
							data={{
								type: "error",
								text: "Tickets to the 2025 Lady in Red Gala are now sold out.",
							}}
						/>
						<p
							style={{
								marginTop: "25px",
								textAlign: "center",
								fontSize: "20px",
							}}
						>
							<span>
								Please visit our{" "}
								<a href="https://loispope.nationbuilder.com/">donation site</a>{" "}
								to make a donation. To learn more about LIFE call 561.582.8083
								or email{" "}
								<a href="mailto:life@life-edu.org">life@life-edu.org</a>.
							</span>
						</p>
					</div>
				) : (
					<Alert data={{ type: "notice", text: "Page under development." }} />
				)}
			</React.StrictMode>,
			target
		);
	} else {
		ReactDOM.render(
			<React.StrictMode>
				<Alert data={{ type: "error", text: "An error has occurred." }} />
			</React.StrictMode>,
			target
		);
	}
}
